import './style.css';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

Alpine.plugin(intersect);
Alpine.plugin(collapse);

/* dev only */

//window.addEventListener('beforeunload', () => {
//  localStorage.setItem('scrollPosition', window.scrollY);
//});
//
//// Restore scroll position after the page loads
//window.addEventListener('load', () => {
//  const scrollPosition = localStorage.getItem('scrollPosition');
//  if (scrollPosition !== null) {
//    window.scrollTo({top: parseInt(scrollPosition, 10), left: 0, behavior:"smooth"});
//    localStorage.removeItem('scrollPosition'); // Clean up
//  }
//});

/* dev only */

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', async () => {
  //for dynamic, per page modules
  let module = document.getElementById("content").dataset.module;

  if (module) {
    const modules = import.meta.glob('./*.js');
    
    const loadModule = async (moduleName) => {
      if (modules[`./${moduleName}.js`]) {
        return await modules[`./${moduleName}.js`]();
      } else {
        console.error(`Module './${moduleName}.js' not found.`);
      }
    };

    // Load and register the Alpine.js module
    const importedModule = await loadModule(module);
    if (importedModule) {
      Alpine.data(module, importedModule.default);
    }
  }

  Alpine.data('gallery', (imagesFromBackend) => ({
    images: imagesFromBackend,
    selectedImage: imagesFromBackend.length ? imagesFromBackend[0] : '',
    currentIndex: 0,
    isLoading: false,
    loaded: false,
    
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
        this.selectedImage = this.images[this.currentIndex];
      }
    },
    
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.selectedImage = this.images[this.currentIndex];
      }
    },

    onImageLoad() {
      this.isLoading = false;
    }
  }));

  Alpine.start();
});
